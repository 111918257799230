:export {
  icon-size: 24;
}

.container {
  @apply w-full
    rounded-[40px]
    shadow
    bg-no-repeat
    bg-cover
    bg-bottom
    pt-12
    pb-8
    px-5;
  @apply md:pb-20 md:bg-center;
  @apply bg-[url('../../../../../../../libs/ui-components/src/assets/images/welcome-sm.jpg')];
  @apply md:bg-[url('../../../../../../../libs/ui-components/src/assets/images/welcome-md.jpg')];
  @apply lg:bg-[url('../../../../../../../libs/ui-components/src/assets/images/welcome.jpg')];

  height: calc(100vh - 96px);
}

.content {
  @apply w-full
    h-full
    flex
    flex-col
    justify-between;
  @apply md:w-9/12 md:mx-auto;
  @apply xl:w-7/12;
}

.above {
  @apply h-1/3 flex justify-center items-start;
}

.below {
  @apply h-2/3 flex flex-col justify-end;
  @apply md:justify-between md:items-center;
}

.logo {
  @apply w-16 h-auto;
  @apply md:w-24;
}

.text {
  @apply text-center space-y-3 text-white mb-8;
  @apply md:mb-0 md:space-y-6;
}

.title {
  @apply text-7xl font-semibold;
  @apply md:text-[72px] md:leading-[75.6px];
}
