.container {
  @apply w-full flex flex-col space-y-9 border-t border-stale-50 pt-11;
}

.checkbox {
  button,
  div {
    @apply inline-flex #{!important};
  }
}

.legal-document-content {
  @apply mt-5;
}
