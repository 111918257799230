.input-digit {
  @apply w-12 h-14
    cursor-pointer
    text-center
    border border-gray-400
    rounded-lg
    caret-transparent;
  @apply focus:outline-black
    focus-visible:outline-black
    focus-within:outline-black;

  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  &--error {
    @apply border-red-400;
  }
}
