:export {
  icon-color-error: theme('colors.red.400');
  icon-color-warn: theme('colors.orange.150');
  icon-size: 24;
}

.container {
  @apply lg:flex lg:space-x-6 lg:relative;

  height: calc(100vh - 96px);
}

.aside {
  @apply w-5/12
    rounded-[40px]
    shadow
    bg-top
    bg-no-repeat
    bg-cover
    bg-[url('../../assets/images/full-alert.jpg')]
    hidden;
  @apply lg:block;

  &-content {
    @apply w-full py-10 px-12;
  }
}

.logo {
  @apply w-16 h-auto;
}

.content {
  @apply w-full h-full bg-blue-0 rounded-[40px] py-12 px-5 shadow;
  @apply lg:w-7/12 md:px-16;

  &-logo {
    @apply flex justify-center items-start;
    @apply lg:hidden;
  }
}

.title {
  @apply flex mt-12 justify-between items-center;
}

.description {
  @apply mt-6;
}

.actions {
  @apply mt-20 flex;

  &--end {
    @apply justify-end;
  }
}

.chat {
  @apply absolute right-9 bottom-16;
  @apply md:right-14 md:bottom-16;
  @apply lg:right-5 lg:bottom-5;
}

.text {
  @apply w-2/3;
}

.icon {
  @apply w-14 h-14 rounded-full flex items-center justify-center;

  &--warn {
    @apply bg-orange-50/30;
  }

  &--error {
    @apply bg-red-400/10;
  }
}
