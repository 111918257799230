.container {
  @apply flex
    flex-col
    items-center
    justify-center
    bg-white
    rounded-[40px]
    px-5;

  height: calc(100vh - 96px);
}

.title {
  @apply mt-11 text-center;
}

.description {
  @apply mt-4 text-stale-400 text-center;
}
