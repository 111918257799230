.container {
  @apply fixed
    top-0
    left-0
    w-full
    h-screen
    bg-black/80
    flex
    items-center
    justify-center
    z-[13];
  @apply m-0 #{!important};
}

.content {
  @apply w-full h-auto;

  &--loading {
    @apply h-full flex items-center justify-center;
  }
}

.modal {
  @apply w-[22rem]
    min-h-[320px]
    max-h-[640px]
    py-9
    px-6
    bg-white
    rounded-3xl
    flex
    flex-col
    justify-between
    overflow-y-scroll;
  @apply md:w-[29rem];

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &--full-screen {
    @apply w-full
      min-h-screen
      max-h-screen
      h-screen
      rounded-none
      justify-start
      px-7;
    @apply md:px-20;
  }

  .content {
    @apply flex items-center justify-center flex-col;
  }
}

.header {
  @apply relative w-full h-8 flex items-center justify-center mb-12;
}

.title {
  @apply text-stale-700;
}

.close {
  @apply absolute top-0 right-0;
}

.footer {
  @apply w-full h-auto mt-10;
}
