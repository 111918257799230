:export {
  icon-color: theme('colors.black');
}

.label {
  @apply pb-2;

  &--disabled {
    @apply text-gray-400;
  }
}

.error {
  @apply text-red-400;
}

.control {
  @apply flex
        items-center
        appearance-none
        border
        border-solid
        border-stale-100
        h-auto
        min-h-[3.5rem]
        justify-between
        overflow-hidden
        px-2 py-[0.4rem]
        rounded-2xl
        text-start text-stale-400
        transition-all
        bg-white
        w-full;

  &--error {
    @apply border-2 border-red-400 text-red-400;
  }

  &--readonly {
    @apply pointer-events-none bg-blue-100 text-gray-300;
  }

  &:active,
  &:focus {
    @apply text-black border-blue-500 border-2;
  }

  &:hover {
    @apply border-stale-300 bg-gray-0 text-stale-700;
  }
}

.option {
  @apply cursor-pointer;

  &:hover,
  &:focus {
    @apply bg-gray-100;
  }
}

.content {
  @apply absolute
        bg-white
        inset-x-0
        rounded-2xl
        border
        border-solid
        border-stale-100
        h-[160px]
        transition-all
        overflow-y-scroll
        z-[1];

  & > * {
    @apply p-[8px];
  }
}

.placeholder {
  @apply text-stale-400;

  &--opened {
    @apply text-black;
  }
}

.icon {
  @apply transition-transform;

  &--rotated {
    @apply rotate-180;
  }
}
