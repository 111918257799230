.container {
  @apply flex w-[328px] h-full items-center justify-center mt-[58px] mx-auto;
  @apply md:w-[384px];
}

.form {
  @apply w-full;
}

.logo {
  @apply block mb-[50px];
  @apply lg:hidden;
}

.title {
  @apply mb-[40px];
}

.space {
  @apply mt-[30px];
}

.checks,
.recaptcha,
.button {
  @apply mt-[40px];
}

.checks {
  @apply space-y-3;
}

.button {
  @apply flex justify-center;
  @apply md:block;
}

.privacy-policy,
.opening-acceptance {
  button {
    @apply inline;
  }
}
