.title {
  @apply text-center;
}

.loader {
  @apply mt-8 flex items-center justify-center;
}

.question {
  @apply mt-8 text-center text-stale-700;
}

.actions {
  @apply flex flex-col items-end space-x-4 space-y-0;
  @apply md:flex-row space-y-4;
}
