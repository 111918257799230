.container {
  @apply flex-row space-x-1 space-y-0;
  @apply md:space-x-4;

  input {
    @apply uppercase;
  }
}

.has-no-rfc-checkbox {
  @apply mt-4 #{!important};
  @apply md:mt-[38px] #{!important};
}

.checkbox-loader {
  @apply w-44;
}
