.container {
  @apply w-full min-h-screen py-12 bg-blue-100;
}

.content {
  @apply max-w-[22rem] mx-auto;
  @apply md:max-w-[46rem];
  @apply lg:max-w-[71rem];
  @apply 2xl:max-w-[84rem];
}
