:export {
  icon-color: theme('colors.white');
  icon-size: 24;
}

.container {
  @apply w-full;
  @apply lg:w-9/12 md:mt-32;
}

.description {
  @apply text-stale-700 mt-3;
}

.buttons {
  @apply mt-16 flex flex-col space-y-6 mb-28;
  @apply md:flex-row md:space-x-6 md:space-y-0;
}

.huawei {
  img {
    @apply mr-2;
  }
}

.header {
  @apply mb-0;
}

.title {
  @apply flex justify-center;
}

.subtitle {
  @apply text-center text-stale-700 mt-7;
}

.frame {
  @apply mt-10 flex justify-center;
}

.code {
  @apply border border-gray-200 rounded-2xl p-6;
}
