.container {
  @apply bg-white rounded-[40px] py-10 pb-6;
  @apply md:py-12;
  @apply lg:py-[62px];

  &--secondary {
    @apply bg-blue-0;
  }
}

.header-container {
  @apply px-8;
  @apply md:px-20;
  @apply lg:px-[200px];
}

.header {
  @apply flex items-center justify-between;
}

.logo {
  @apply w-16 h-auto;
  @apply lg:w-20;
}

.stepper,
.content {
  @apply mt-[70px];
}

.stepper {
  @apply px-8;
  @apply md:px-0;

  &--full {
    @apply px-0;
  }
}

.content {
  @apply px-8;
  @apply md:px-20;
  @apply lg:px-[200px];
}

.section {
  @apply w-full;

  &-description {
    @apply mt-3 text-stale-400;
  }

  &-header {
    @apply mt-5;
  }

  &-content {
    @apply mt-9 pt-10 mb-16 space-y-8 border-t border-stale-50;
    @apply md:space-y-11;
  }
}

.row {
  @apply w-full flex flex-col items-start space-y-8;
  @apply md:flex-row md:space-x-4 md:space-y-0;
}

.footer {
  @apply w-full
    h-auto
    pt-6
    pb-0
    flex
    flex-col
    space-y-6
    items-center
    border-t
    border-stale-50
    px-8;
  @apply md:px-20
    md:flex-row
    md:justify-between
    md:space-y-0
    md:h-24
    md:py-6;
  @apply lg:px-[200px];

  &--end {
    @apply justify-end;
  }
}
