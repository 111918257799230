:export {
  icon-size: 20;
  info-icon-color: theme('colors.blue.500');
  info-icon-size: 32;
}

.text {
  @apply text-center;
}

.rejected {
  &-title {
    @apply space-y-2;
  }

  &-step {
    @apply mt-8;

    &-label {
      @apply flex
        w-6 h-6
        items-center
        justify-center
        bg-black
        rounded-full
        text-white
        mb-2;
    }
  }

  &-disclaimer {
    @apply mt-8 flex items-center space-x-2;
  }
}

.icon {
  @apply inline;
}

.required {
  @apply flex flex-col items-center py-2 px-4 text-center w-80 mx-auto;
  @apply md:w-[48rem];

  &-title,
  &-description {
    @apply mt-5;
  }

  &-question {
    @apply mt-4;
  }

  &-buttons {
    @apply mt-5;

    &--exit {
      @apply mt-5;
    }
  }

  &-chat {
    @apply flex justify-end;
  }
}

.content {
  @apply h-full;
}
