:export {
  close-size: 40;
  icon-size: 24;
}

.container {
  @apply lg:flex lg:space-x-6;

  height: calc(100vh - 96px);
}

.image {
  @apply w-5/12
    rounded-[40px]
    shadow
    bg-top
    bg-no-repeat
    bg-cover
    bg-[url('../../../../../../../libs/ui-components/src/assets/images/curp-screen.jpeg')]
    hidden;
  @apply lg:block;
}

.content {
  @apply w-full
    h-full
    bg-blue-0
    rounded-[40px]
    shadow
    py-12 px-5
    flex
    flex-col;
  @apply md:block md:px-20;
  @apply lg:w-7/12;
}

.above {
  @apply flex justify-center items-start h-1/4;
  @apply md:h-auto;
  @apply lg:justify-start;
}

.below {
  @apply flex flex-col justify-between h-3/4;
  @apply md:h-auto;
}

.logo {
  @apply w-16 h-auto;
}

.text {
  @apply md:mt-16;
}

.subtitle {
  @apply text-stale-400;
  @apply md:mt-3;
}

.input-container {
  @apply md:mt-24;
}

.link {
  @apply mt-2;
}

.button-container {
  @apply flex items-center justify-center;
  @apply md:mt-24;
  @apply lg:justify-end;
}

.renapo-link {
  @apply text-white underline;
}

.info {
  button {
    @apply inline;
  }
}

.help {
  &-content {
    @apply relative;
  }

  &-aside {
    @apply w-full py-10 px-12 flex space-x-6;
  }

  &-logo {
    @apply flex justify-center items-start;
    @apply lg:justify-start;
  }

  &-title {
    @apply mt-16;
  }

  &-schedule {
    @apply mt-6;
  }

  &-phone {
    @apply mt-20 flex items-center space-x-4;
  }

  &-email {
    @apply mt-12 flex items-center space-x-4;
  }
}

.chat {
  @apply w-[90%] absolute bottom-8 md:right-8 lg:w-auto;
}

.close {
  @apply w-full mb-8;
}
