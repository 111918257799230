.container {
  @apply py-[12px] px-[16px] mt-[30px] bg-blue-100 rounded-[4px];
}

.label {
  @apply flex justify-between cursor-pointer;
}

.icon {
  @apply transition-transform;

  &--show {
    @apply rotate-0;
  }

  &--hide {
    @apply rotate-180;
  }
}

.content {
  @apply transition-all;

  &--show {
    @apply block mt-[16px];
  }

  &--hide {
    @apply h-0 opacity-0 invisible;
  }

  ul {
    @apply p-0 ml-[8px];

    list-style-type: '-';

    li {
      @apply pl-[8px] mb-[8px];
    }
  }
}
