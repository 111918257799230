:export {
  icon-size: 24;
}

.container {
  @apply w-full flex;
}

.button {
  @apply ml-2;
}
