:export {
  icon-color: theme('colors.black');
  icon-size: 24;
}

.container {
  @apply w-full;
}

.upper {
  @apply uppercase;
}

.numbers {
  @apply md:space-x-1;
}

.outside-number {
  @apply w-full space-y-2;
}

.footer {
  @apply flex-col-reverse space-y-0;
  @apply md:flex-row;

  &-link {
    @apply mt-6;
    @apply md:mt-0;
  }
}
