/* stylelint-disable declaration-no-important */
.container {
  @apply mt-[30px];

  &--completed {
    @apply text-green-400 #{!important};

    &::before {
      @apply bg-transparent bg-no-repeat #{!important};

      background-image: url(../../../../../../../libs/ui-components/src/assets/images/icons/atm-icn-check-green.svg) !important;
    }
  }

  &--error {
    @apply text-red-400 #{!important};

    &::before {
      @apply bg-transparent bg-no-repeat #{!important};

      background-image: url(../../../../../../../libs/ui-components/src/assets/images/icons/atm-icn-close-red.svg) !important;
    }
  }
}

.requirements {
  @apply mt-[20px] transition-all;

  ul {
    @apply p-0 m-0 list-none;

    li {
      @apply flex items-center text-gray-700;

      &::before {
        @apply inline-block
          w-[14px]
          h-[14px]
          mr-[10px]
          bg-gray-400
          rounded-[8px]
          align-bottom;

        content: '';
      }
    }
  }
}
