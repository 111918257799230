.container {
  @apply flex w-[328px] h-full items-center mx-auto;
  @apply md:w-[384px];
}

.content {
  @apply flex flex-col justify-center gap-4 text-center mt-[80px];
}

.icon {
  @apply w-[24px] h-[24px] mx-auto;
}

.recaptcha {
  @apply mt-[16px] space-y-4;

  &-item {
    @apply flex justify-center;
  }
}

.link {
  @apply mx-auto;
}
