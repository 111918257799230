@import '~@gbm/starman-next/index.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=PT+Mono&display=swap');

@font-face {
  font-display: swap;
  font-family: 'pp-mori';
  font-stretch: normal;
  font-style: normal;
  font-weight: 100;
  src: url('./assets/fonts/PPMori-Extralight.eot');
  src: url('./assets/fonts/PPMori-Extralight.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/PPMori-Extralight.woff') format('woff'),
    url('./assets/fonts/PPMori-Extralight.ttf') format('truetype'),
    url('./assets/fonts/PPMori-Extralight.svg#PPMori-ExtraLight') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'pp-mori';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/PPMori-Regular.eot');
  src: url('./assets/fonts/PPMori-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/PPMori-Regular.woff') format('woff'),
    url('./assets/fonts/PPMori-Regular.ttf') format('truetype'),
    url('./assets/fonts/PPMori-Regular.svg#PPMori-Regular') format('svg');
}
@font-face {
  font-display: swap;
  font-family: 'pp-mori';
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/PPMori-SemiBold.eot');
  src: url('./assets/fonts/PPMori-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/PPMori-SemiBold.woff') format('woff'),
    url('./assets/fonts/PPMori-SemiBold.ttf') format('truetype'),
    url('./assets/fonts/PPMori-SemiBold.svg#PPMori-SemiBold') format('svg');
}
