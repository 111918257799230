.container {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply my-3;
  }

  p {
    @apply my-4;
  }
}
