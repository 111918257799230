:export {
  icon-color--info: theme('colors.blue.600');
  icon-color--success: theme('colors.green.500');
  icon-size: 24;
}

.reviewing {
  &-icon {
    @apply flex justify-center items-center w-16 h-16 rounded-full mx-auto bg-blue-105;
  }

  &-title {
    @apply text-center mt-6;
  }

  &-description {
    @apply text-center text-stale-700 mt-6;
  }
}

.success {
  &-icon {
    @apply flex justify-center items-center w-16 h-16 rounded-full mx-auto bg-green-500/[0.14] mt-6;
  }

  &-title {
    @apply text-center;
  }
}
