.radios {
  @apply space-y-1;

  span {
    @apply text-stale-700;
  }

  &-error {
    @apply text-red-400;
  }
}
