.container {
  @apply flex w-[328px] h-full items-center mx-auto;
  @apply md:w-[384px];
}

.content {
  @apply flex flex-col mt-[80px] text-center justify-center;
}

.image {
  @apply w-[24px] h-[24px] mx-auto;
}

.title,
.subtitle {
  @apply mt-[16px];
}

.sent {
  @apply mt-[24px];
}

.help {
  @apply font-semibold;
}

.link {
  @apply flex mt-[48px] mx-auto;
}
