:export {
  icon-size: 24;
}

.container {
  @apply w-full;
}

.content {
  @apply mt-0 pt-0 border-t-0;
}
