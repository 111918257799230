:export {
  icon-color: theme('colors.black');
  icon-size: 24;
}

.container {
  @apply flex w-[328px] h-full items-center mx-auto;
  @apply md:w-[384px];
}

.form {
  @apply w-full;
}

.title,
.subtitle {
  @apply mb-[40px];
}

.button {
  @apply mt-[48px];
}

.back {
  @apply absolute top-[72px] bottom-auto;
  @apply lg:top-auto lg:bottom-[72px];
}
