.logo-marks {
  @apply flex flex-row items-end;

  &__logo {
    @apply w-[100px] mr-4;
  }

  &__carousel {
    @apply overflow-hidden w-[300px] h-[56px];
    @apply md:h-[25px];

    &-container {
      @apply relative right-0 bottom-0;
      @apply animate-[slide-animation-mobile_10s_infinite];
      @apply md:animate-[slide-animation_10s_infinite];
    }
  }

  &__slide {
    @apply flex w-full h-[60px] items-end;
    @apply md:w-[300px] md:h-[28px] md:items-center;
  }

  &--light {
    @apply text-white;
  }

  &--dark {
    @apply text-black;
  }

  @keyframes slide-animation {
    15% {
      bottom: 0;
      opacity: 1;
    }

    30%,
    45% {
      bottom: 28px;
    }

    60%,
    75% {
      bottom: 56px;
    }

    90%,
    100% {
      bottom: 84px;
    }
  }

  @keyframes slide-animation-mobile {
    15% {
      bottom: 0;
      opacity: 1;
    }

    30%,
    45% {
      bottom: 62px;
    }

    60%,
    75% {
      bottom: 124px;
    }

    90%,
    100% {
      bottom: 186px;
    }
  }
}
