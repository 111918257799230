:export {
  icon-color: theme('colors.black');
  icon-size: 24;
}

.container {
  @apply w-full;
}

.empty {
  @apply hidden;
  @apply md:block md:w-full;
}
