:export {
  icon-size: 24;
}

.container,
.column {
  @apply w-full;
}

.empty {
  @apply hidden;
  @apply md:block md:w-full;
}

.select {
  @apply mt-7;
}

.button {
  @apply w-full flex justify-center items-center space-x-1 mt-7;
}
