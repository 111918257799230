.container {
  @apply flex w-full min-h-screen;

  &--center {
    @apply block min-h-[auto] mt-20 mb-[100px] overflow-y-scroll;
  }
}

.content {
  @apply min-w-full;
  @apply lg:min-w-[592px];
}

.image {
  @apply relative overflow-hidden w-full h-screen hidden;
  @apply lg:block;

  &--animated {
    @apply w-full h-screen bg-cover;
    @apply animate-[zoom-in_30s_forwards_linear_1s];
  }

  @keyframes zoom-in {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1.4);
    }
  }
}

.close {
  @apply fixed z-[11] top-0 right-0 mt-8 mr-8;
  @apply md:absolute;
  @apply lg:mt-[18px] lg:mr-[92px];
}

.mark {
  @apply hidden absolute z-[1] top-0 mt-[26px] ml-[92px];
  @apply lg:block;
}
