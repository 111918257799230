:export {
  icon-size: 24;
}

.container,
.input-second-last-name,
.genre-container,
.birthdate-container {
  @apply w-full;
}

.checkbox-container {
  @apply mt-2;
}

.genre-container {
  &-radios {
    @apply flex space-x-1;
  }

  &-error {
    @apply text-red-400;
  }
}

.birthdate {
  &-row {
    @apply flex-col space-y-1 space-x-0;
    @apply lg:flex-row lg:space-y-0 lg:space-x-1;
  }

  &-selects {
    @apply w-full flex space-x-1;
    @apply lg:w-2/3;
  }

  &-year {
    @apply w-full;
    @apply lg:w-1/3;
  }

  &-error {
    @apply text-red-400;
  }
}

.upper {
  @apply uppercase;
}
