:export {
  blue-600: theme('colors.blue.600');
  icon-color--error: theme('colors.red.400');
  icon-size: 24;
  icon-size--small: 16;
  stale-50: theme('colors.stale.50');
}

.rejected-documents,
.document-tampered,
.reached-attempts {
  &-icon {
    @apply flex justify-center items-center w-16 h-16 rounded-full mx-auto bg-red-20;
  }

  &-title {
    @apply text-center mt-6;
  }

  &-description {
    @apply text-center text-stale-700 mt-6;
  }

  &-link {
    @apply underline font-semibold;
  }
}

.container {
  @apply w-full;
  @apply lg:w-[59rem] lg:mx-auto;
}

.close {
  @apply w-full flex items-center justify-center relative;

  &-button {
    @apply absolute left-0;
  }
}

.content {
  @apply w-full mt-10 grid gap-3;
  @apply lg:gap-5 lg:grid-cols-[auto_19rem] lg:grid-rows-[16rem_13rem_auto];

  &--status {
    @apply lg:grid-rows-[auto_auto];
  }
}

.notification {
  @apply w-full mt-10;

  &--white {
    @apply text-white;
  }
}

.deposits {
  @apply w-full bg-white py-12 px-6 rounded-3xl;

  &-title {
    @apply text-stale-700;
  }

  &-amount {
    @apply mt-4 text-blue-600;
  }

  &-bar {
    @apply mt-8;
  }

  &-limit {
    @apply flex mt-5 items-center justify-between;
  }

  &--full {
    @apply lg:col-span-2;
  }
}

.start {
  @apply w-full bg-stale-1100 py-12 px-6 rounded-3xl;
  @apply lg:row-span-2;

  &-title {
    @apply text-white;
  }

  &-checks {
    @apply text-stale-150 mt-5 space-y-5;
    @apply md:flex md:space-y-0;
    @apply lg:block lg:space-y-5;
  }

  &-privacy-check {
    button,
    p {
      @apply inline #{!important};
    }
  }

  &-link {
    @apply text-white underline;
  }

  &-button {
    @apply mt-10;
  }
}

.description {
  @apply w-full bg-blue-0 py-12 px-6 rounded-3xl;
  @apply lg:row-span-2;

  &-text {
    @apply mt-10;
  }

  &-question {
    @apply mt-8;
  }

  &-list {
    @apply list-decimal px-6 space-y-4;
    @apply lg:px-14;

    &-container {
      @apply mt-10;
    }
  }

  &-button {
    @apply mt-10 flex items-center justify-center;
    @apply justify-end;
  }

  &-video {
    @apply w-[22rem] p-0 bg-transparent min-h-[auto] rounded-none;
    @apply md:w-[46rem];
    @apply lg:w-[49rem];

    video {
      @apply w-full;
    }
  }

  &-close {
    @apply absolute top-4 right-4;
    @apply md:top-7 md:right-7;
  }
}

.status {
  @apply w-full bg-blue-0 py-12 px-6 rounded-3xl;
  @apply lg:col-span-2;

  &-alert,
  &-accordion {
    @apply mt-10;
  }

  &-alert {
    &--rejected {
      @apply text-orange-400;
    }

    &--error {
      @apply text-red-400;
    }
  }

  &-accordion {
    p {
      @apply text-stale-700;
    }
  }
}
