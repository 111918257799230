.container {
  @apply flex w-[328px] h-full items-center mx-auto;
  @apply md:w-[384px];
}

.form {
  @apply w-full;
}

.logo {
  @apply block mb-[50px];
  @apply lg:hidden;
}

.title {
  @apply mb-[40px];
}

.password-input {
  @apply flex flex-col items-start space-y-2 mt-[30px];
}

.button {
  @apply flex justify-center mt-[50px];
  @apply md:block;
}

.register {
  @apply flex items-center justify-center mt-[48px] mb-[72px];
  @apply md:justify-start md:mb-0;
}
