.text {
  @apply opacity-0;
}

.container {
  @apply bg-white
    flex
    w-12
    h-12
    items-center
    p-0
    m-0
    bg-center
    bg-no-repeat
    rounded-[50%]
    cursor-pointer
    object-contain
    shadow-2xl
    transition-all;

  background-image: url(../../assets/images/icons/icon-help.svg);
  background-size: 24px;

  &:hover,
  &:focus {
    @apply w-[200px] rounded-lg;

    background-position-x: 160px;

    .text {
      @apply m-[14px] text-base opacity-100 transition-opacity;
    }
  }
}
