:export {
  icon-size: 20;
}

.container {
  @apply w-full;
}

.title {
  @apply flex justify-between items-center text-stale-400 mb-16;
  @apply md:justify-start md:space-x-3;
}

.content {
  @apply space-y-8;
  @apply md:space-y-12;
}

.item {
  @apply w-full;

  &-text {
    @apply mb-2 uppercase;

    &--break {
      @apply break-all;
    }
  }

  &-label {
    @apply text-stale-700;
  }
}
