:export {
  icon-color: theme('colors.black');
  icon-size: 24;
}

.container,
.fiel-container {
  @apply w-full;
}

.toggle {
  @apply w-full;
  @apply md:w-1/2;
}

.tax-country {
  @apply w-full;

  &-checks {
    @apply block mt-7 space-y-4;
    @apply md:flex md:space-y-0;
  }
}

.empty {
  @apply hidden;
  @apply md:block md:w-full;
}

.rfc {
  @apply mt-16 #{!important};

  &-check {
    @apply mt-6 #{!important};
  }
}

.fiel {
  @apply mt-16 #{!important};

  input {
    @apply uppercase;
  }

  &-options {
    @apply flex items-center space-x-1;
  }
}
