:export {
  icon-color: theme('colors.black');
  icon-size: 24;
}

.container,
.options {
  @apply w-full;
}

.empty {
  @apply hidden;
  @apply md:block md:w-full;
}

.link {
  @apply mt-8 #{!important};
}

.modal {
  &-header {
    @apply mb-6;
  }

  &-title {
    @apply text-center mb-6;
  }

  &-list {
    @apply px-6;
  }
}

.notification {
  @apply flex items-center justify-center mb-8;
}

.address-link {
  button {
    @apply inline;
  }
}
