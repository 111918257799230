:export {
  icon-size: 24;
}

.help {
  &-container {
    @apply w-64;
  }

  &-title {
    @apply mt-8;
  }

  &-info-container {
    @apply mt-8 flex space-x-4;
  }

  &-phone-icon {
    @apply w-10 h-10 bg-gray-100 rounded-lg p-2;
  }

  &-info {
    @apply mb-2;
  }

  &-button {
    @apply mt-10;
  }

  &-link {
    @apply mt-6;
  }
}

.form {
  @apply w-64;
}

.title {
  @apply mt-8;
}

.subtitle {
  @apply mt-4;
}

.inputs {
  @apply mt-6 flex space-x-4;
}

.copy-icon {
  @apply mt-10 bg-gray-100 rounded-lg py-4 px-6;
}

.button {
  @apply mt-10;
}
