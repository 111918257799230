:export {
  icon-size: 24;
}

.title {
  @apply text-center;
}

.description {
  @apply text-center mt-6 text-stale-700;

  &-text {
    @apply font-semibold;
  }
}

.input {
  @apply mt-10;
}

.link {
  @apply inline-flex items-start;
}
